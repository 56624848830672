import { get, post } from "@/utils/axios";

export function fetchLbGroupList(
  regionGuid,
  nodeGuids,
  lbGroupType,
  includeChildren
) {
  return post("/lbgroup/LbGroupListFromNode", {
    customerGuid: regionGuid,
    nodeGuids: nodeGuids || [],
    lbGroupType,
    includeChildren: includeChildren || false
  });
}

export function fetchLbGroupDetails(lbGroupGuid) {
  return get("/lbgroup/LbGroupDetailsFromGuid", { lbGroupGuid });
}

export function fetchLbGroupPersonList(lbGroupGuid) {
  return get("/lbgroup/AssignedLbGroupPersonList", { lbGroupGuid });
}

export function fetchLbGroupPickableLbList(lbGroupGuid) {
  return get("/lbgrouplbinfo/PickableLbList", {
    lbGroupGuid
  });
}

export function fetchLbGroupPickedLbList(lbGroupGuid) {
  return get("/lbgrouplbinfo/PickedLbList", { lbGroupGuid });
}

export async function createOneNewLbGroup(
  regionGuid,
  nodeGuid,
  lbGroupType,
  lbGroupName,
  beginDate,
  endDate
) {
  let response = await post(
    `/lbgroup/AddOneLbGroupToManageNode/${regionGuid}`,
    {
      nodeGuid,
      lbGroupType,
      lbGroupName,
      beginDate,
      endDate
    }
  );
  return response.guid;
}

export async function assignPersonListToLbGroup(lbGroupGuid, personGuidList) {
  let response = await post(
    `/lbgroup/AssignPersonListToLbGroup/${lbGroupGuid}`,
    personGuidList || []
  );
  return response.value;
}

export async function unbindLbGroupFromPerson(lbGroupGuid, personUniGuid) {
  let response = await post("/lbgroup/UnbindLbGroupFromPerson", {
    lbGroupGuid,
    personUniGuid
  });
  return response.guid;
}

export async function addLbsToLbGroup(lbGroupGuid, lbGuidList) {
  let response = await post(
    `/lbgrouplbinfo/AddLbsToLbGroup/${lbGroupGuid}`,
    lbGuidList
  );
  return response.guid;
}

export async function removeLbsfromLbGroup(lbGroupGuid, removeLbGuidList) {
  let response = await post(
    `/lbgrouplbinfo/RemoveLbsFromLbGroup/${lbGroupGuid}`,
    removeLbGuidList
  );
  return response.guid;
}

export async function updateLbGroupLbOrder(lbGroupGuid, lbGuidList) {
  let response = await post(
    `/lbgrouplbinfo/UpdateLbGroupLbOrder/${lbGroupGuid}`,
    lbGuidList
  );
  return response.guid;
}

export async function updateLbGroupName(lbGroupGuid, lbGroupName) {
  let response = await post(`/lbgroup/UpdateLbGroupName/${lbGroupGuid}`, {
    value: lbGroupName
  });
  return response.guid;
}

export async function updateLbGroupTestPwd(lbGroupGuid, testPwd) {
  let response = await post(`/lbgroup/UpdateLbGroupTestPwd/${lbGroupGuid}`, {
    value: testPwd
  });
  return response.guid;
}

export async function updateBeginEndDateForLbGroup(
  lbGroupGuid,
  { beginDate, endDate }
) {
  let response = await post(
    `/lbgroup/UpdateBeginEndDateForLbGroup/${lbGroupGuid}`,
    {
      dateTimeFrom: beginDate,
      dateTimeTo: endDate
    }
  );
  return response.guid;
}

export async function updateIsShowScaReportForLbGroup(
  lbGroupGuid,
  isShowScaReport
) {
  let response = await post(
    `/lbgroup/UpdateIsShowScaReportForLbGroup/${lbGroupGuid}`,
    {
      value: isShowScaReport
    }
  );
  return response.guid;
}

export async function updateIsContinuousTestForLbGroup(
  lbGroupGuid,
  isContinuousTest
) {
  let response = await post(
    `/lbgroup/UpdateIsContinuousTestForLbGroup/${lbGroupGuid}`,
    {
      value: isContinuousTest
    }
  );
  return response.guid;
}

export async function updateIsAccountSingleAttemptForLbGroup(
  lbGroupGuid,
  isAccountSingleAttempt
) {
  let response = await post(
    `/lbgroup/UpdateIsAccountSingleAttemptForLbGroup/${lbGroupGuid}`,
    {
      value: isAccountSingleAttempt
    }
  );
  return response.guid;
}

export async function deleteOneLbGroup(lbGroupGuid) {
  let response = await post("/lbgroup/DeleteOneLbGroup", { guid: lbGroupGuid });
  return response.guid;
}

export async function autoGenerateTestPwd(regionGuid) {
  let response = await post("/lbgroup/GenerateNewTestPwd", {
    value: regionGuid
  });
  return response.value;
}

export async function isTestPwdDuplicated(testPwd) {
  let response = await post("/lbgroup/IsTestPwdDuplicate", {
    value: testPwd
  });
  return response.value;
}
