<template>
  <div>
    <div v-if="!caseStatList.length">未找到任何案例</div>
    <div v-else id="echartstat" class="mt-6"></div>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppMessageBox from "@/components/AppMessageBox";
import _ from "lodash";
import {
  drawTestCaseStatChart,
  disposeChart
} from "@/utils/charts/chartsTestCaseStat";
import { createdDateGroupByFunc } from "@/utils/dateTime";

export default {
  components: {
    AppMessageBox
  },

  props: {
    title: {
      type: String,
      required: true
    },
    chartMode: {
      type: Number,
      required: true
    },
    caseStatList: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      errorMsg: ""
    };
  },

  watch: {
    caseStatList() {
      this.reloadChartDraw();
    },
    chartMode() {
      this.reloadChartDraw();
    }
  },

  computed: {
    caseGroupedKeys() {
      if (this.caseStatList.length) {
        let lodChain = _.chain(this.caseStatList);
        if (this.chartMode === 0) {
          lodChain = lodChain.groupBy(createdDateGroupByFunc);
        } else if (this.chartMode === 1) {
          lodChain = lodChain.groupBy(ca => ca.lbDispName);
        }
        return lodChain.map((_, key) => key).value();
      }
      return [];
    }
  },

  methods: {
    reloadChartDraw() {
      if (this.caseStatList.length) {
        disposeChart();
        this.$nextTick(() => {
          this.drawCaseStatData();
        });
      }
    },
    getCaseGroupedCount(statusName) {
      if (this.caseStatList.length && this.caseGroupedKeys.length) {
        let lodChain = _.chain(this.caseStatList).filter({
          status: statusName
        });
        return this.caseGroupedKeys.map(key => {
          let caseWithKey = null;
          if (this.chartMode === 0) {
            caseWithKey = lodChain
              .countBy(gtc => gtc.createdDate.includes(key))
              .value().true;
          } else if (this.chartMode === 1) {
            caseWithKey = lodChain
              .countBy(gtc => gtc.lbDispName === key)
              .value().true;
          }
          return caseWithKey || "";
        });
      }
      return [];
    },
    // echarts 图表
    drawCaseStatData() {
      try {
        drawTestCaseStatChart(document.getElementById("echartstat"), {
          chartTitle: `${this.title}`,
          chartSubtext: "测量人数统计",
          dateSeries: this.caseGroupedKeys,
          visualMarkData: {
            completed: Object.values(this.getCaseGroupedCount("completed")),
            waitToStart: Object.values(
              this.getCaseGroupedCount("wait_to_start")
            ),
            inProgress: Object.values(this.getCaseGroupedCount("in_progress")),
            saved: Object.values(this.getCaseGroupedCount("saved")),
            cancelled: Object.values(this.getCaseGroupedCount("cancelled"))
          }
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
    }
  },

  created() {
    this.reloadChartDraw();
  }
};
</script>

<style lang="scss" scoped>
#echartstat {
  width: 600px;
  height: 600px;
}
</style>
