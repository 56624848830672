<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mt-4 ml-4">
      <v-card-title>添加量表</v-card-title>
      <v-row class="mx-4 mb-1 flex-nowrap">
        <v-autocomplete
          filled
          dense
          clearable
          chips
          small-chips
          deletable-chips
          multiple
          :items="pickableLbCatItems"
          item-text="lbDispName"
          :item-value="useLbId ? 'lbId' : 'guid'"
          v-model="selectedLbIdOrGuidList"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.lbDispName }}
                <v-icon
                  v-if="!!item.timeboxSeconds"
                  class="ml-3"
                  color="grey"
                  small
                >
                  mdi-timer-outline
                </v-icon>
                <v-icon
                  v-if="!!item.isQuesShuffle"
                  class="ml-3"
                  color="grey"
                  small
                >
                  mdi-shuffle-variant
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <div class="ml-3 d-flex flex-nowrap">
          <AppMenuBtn
            btn-class="mx-2"
            color="primary"
            icon="mdi-arrow-down-drop-circle-outline"
            tooltip="快速选择"
          >
            <v-list>
              <v-list-item
                v-for="(item, idx) in presetLbItemGroups"
                :key="idx"
                @click="addToSelectedLbList(item.value)"
              >
                {{ item.text }}
              </v-list-item>
            </v-list>
          </AppMenuBtn>
          <AppTooltipBtn
            color="primary"
            label="添加量表"
            :disabled="
              !selectedLbIdOrGuidList || !selectedLbIdOrGuidList.length
            "
            @click="addLbList(selectedLbIdOrGuidList)"
          />
        </div>
      </v-row>
    </v-card>
    <v-card flat class="ml-4 mb-10">
      <v-card-title>
        已添加的量表
        <v-spacer></v-spacer>
        <AppTooltipBtn
          class="ml-2"
          text
          color="error"
          icon="mdi-delete-sweep"
          label="删除全部"
          tooltip="删除全部已添加的量表"
          :disabled="!pickedLbItems || !pickedLbItems.length"
          @click="isShowBulkDeleteDialog = true"
        />
      </v-card-title>
      <v-row class="mx-2 mb-6">
        <v-col cols="12">
          <v-list>
            <draggable
              v-if="reorderable"
              v-model="pickedLbItems"
              handle=".drag-handle"
              animation="200"
              @start="isDragging = true"
              @end="isDragging = false"
              @change="lbOrderChanged"
            >
              <transition-group
                type="transition"
                :name="!isDragging ? 'flip-list' : null"
              >
                <v-list-item v-for="item in pickedLbItems" :key="item.guid">
                  <v-sheet
                    class="my-2 px-4 d-flex justify-space-between"
                    width="100%"
                    elevation="2"
                    tile
                  >
                    <v-icon class="drag-handle mr-4">
                      mdi-reorder-horizontal
                    </v-icon>
                    <v-list-item-title>
                      {{ item.lbDispName }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="!!item.timeboxSeconds"
                            class="ml-3"
                            color="grey"
                            v-on="on"
                            v-bind="attrs"
                          >
                            mdi-timer-outline
                          </v-icon>
                        </template>
                        此量表设置了答题时间限制
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="!!item.isQuesShuffle"
                            class="ml-3"
                            color="grey"
                            v-on="on"
                            v-bind="attrs"
                          >
                            mdi-shuffle-variant
                          </v-icon>
                        </template>
                        此量表使用了“乱序答题”模式
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-btn
                        icon
                        color="red"
                        @click="removeLbList([item.guid])"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-sheet>
                </v-list-item>
              </transition-group>
            </draggable>
            <div v-else>
              <v-list-item v-for="item in pickedLbItems" :key="item.guid">
                <v-sheet
                  class="my-2 px-4 d-flex justify-space-between"
                  width="100%"
                  elevation="2"
                  tile
                >
                  <v-list-item-title class="ml-3">
                    {{ item.lbDispName }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="!!item.timeboxSeconds"
                          class="ml-3"
                          color="grey"
                          v-on="on"
                          v-bind="attrs"
                        >
                          mdi-timer-outline
                        </v-icon>
                      </template>
                      此量表设置了答题时间限制
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="!!item.isQuesShuffle"
                          class="ml-3"
                          color="grey"
                          v-on="on"
                          v-bind="attrs"
                        >
                          mdi-shuffle-variant
                        </v-icon>
                      </template>
                      此量表使用了“乱序答题”模式
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon color="red" @click="removeLbList([item.guid])">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-sheet>
              </v-list-item>
            </div>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
    <AppDialog
      v-model="isShowBulkDeleteDialog"
      size="small"
      color="error"
      title="确定要删除全部已添加的量表吗？"
      action-text="全部删除"
      :loading="isBtnLoading"
      @confirm="removeAllPickedLbs"
    >
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import _ from "lodash";
import {
  fetchLbGroupPickableLbList,
  fetchLbGroupPickedLbList,
  addLbsToLbGroup,
  removeLbsfromLbGroup,
  updateLbGroupLbOrder
} from "@/api/lbGroup";
import {
  fetchNodePickableLbList,
  fetchNodePickedLbList,
  addLbsToNode,
  removeLbsFromNode
} from "@/api/manageNode";

export default {
  components: {
    draggable,
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    AppTooltipBtn,
    AppMenuBtn
  },

  props: {
    lbGroupGuid: {
      type: String
    },
    nodeGuid: {
      type: String
    },
    useLbId: {
      type: Boolean,
      default: false
    },
    reorderable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      pickableLbCatItems: [],
      pickedLbItems: [],
      selectedLbIdOrGuidList: [],
      presetLbItemGroups: [],
      isDragging: false,
      isLoading: false,
      isBtnLoading: false,
      errorMsg: "",
      // dialog
      isShowBulkDeleteDialog: false
    };
  },

  watch: {
    lbGroupGuid() {
      this.selectedLbIdOrGuidList = [];
      if (this.lbGroupGuid) {
        this.refreshLbItems();
      } else {
        this.pickedLbItems = [];
      }
    },
    nodeGuid() {
      this.selectedLbIdOrGuidList = [];
      if (this.nodeGuid) {
        this.refreshLbItems();
      } else {
        this.pickedLbItems = [];
      }
    }
  },

  methods: {
    getPresetLbItemGroups() {
      this.presetLbItemGroups = [
        {
          text: "选择全部",
          value: "all"
        }
      ];
    },
    addToSelectedLbList(presetValue) {
      if (presetValue === "all") {
        this.selectedLbIdOrGuidList = this.pickableLbCatItems
          .map(lb => (this.useLbId ? lb.lbId : lb.guid))
          .filter(lbIdOrGuid => lbIdOrGuid);
      }
    },
    async refreshLbItems() {
      try {
        this.isLoading = true;
        let lbItems = [];
        if (this.lbGroupGuid) {
          lbItems = await fetchLbGroupPickableLbList(this.lbGroupGuid);
        } else if (this.nodeGuid) {
          lbItems = await fetchNodePickableLbList(this.nodeGuid);
        }
        // 排序，常用量表需要在最上面
        let categorizedLbItems = _.chain(lbItems)
          .sortBy(lb => (lb.isTopMost ? 0 : 1))
          .groupBy("categoryName")
          .value();
        let lbCatItems = [];
        for (let cat in categorizedLbItems) {
          lbCatItems.push({ divider: true });
          lbCatItems.push({ header: cat === "TOPMOST" ? "常用量表" : cat });
          lbCatItems.push(...categorizedLbItems[cat]);
        }
        this.pickableLbCatItems = lbCatItems;
        if (this.lbGroupGuid) {
          this.pickedLbItems = await fetchLbGroupPickedLbList(this.lbGroupGuid);
        } else if (this.nodeGuid) {
          this.pickedLbItems = await fetchNodePickedLbList(this.nodeGuid);
        }
        // 排序
        if (this.reorderable) {
          this.pickedLbItems = this.pickedLbItems.sort(
            (lb1, lb2) => lb1.order - lb2.order
          );
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async addLbList(lbIdOrGuidList) {
      try {
        this.isLoading = true;
        if (this.lbGroupGuid) {
          await addLbsToLbGroup(this.lbGroupGuid, lbIdOrGuidList);
        } else if (this.nodeGuid) {
          await addLbsToNode(this.nodeGuid, lbIdOrGuidList);
        }
        // 重置控件
        this.selectedLbIdOrGuidList = [];
        await this.refreshLbItems();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async removeLbList(lbGuidList) {
      try {
        this.isLoading = true;
        this.isBtnLoading = true;
        if (this.lbGroupGuid) {
          await removeLbsfromLbGroup(this.lbGroupGuid, lbGuidList);
        } else if (this.nodeGuid) {
          await removeLbsFromNode(lbGuidList);
        }
        // 关闭 delete dialog
        this.isShowBulkDeleteDialog = false;
        // 重置控件
        await this.refreshLbItems();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
      this.isBtnLoading = false;
    },
    async removeAllPickedLbs() {
      // remove 时始终使用 guid
      let pickedLbIdOrGuidList = this.pickedLbItems.map(lb => lb.guid);
      await this.removeLbList(pickedLbIdOrGuidList);
    },
    async lbOrderChanged() {
      try {
        this.isLoading = true;
        let changedLbGuidList = this.pickedLbItems.map(item => item.guid);
        await updateLbGroupLbOrder(this.lbGroupGuid, changedLbGuidList);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    }
  },

  created() {
    this.getPresetLbItemGroups();
    this.refreshLbItems();
  }
};
</script>

<style lang="scss" scoped>
.drag-handle {
  cursor: move;
}
</style>
