import { get, post } from "@/utils/axios";

export async function getUserFieldConfig(nodeGuids) {
  let response = await post("/fieldconfig/PersonFieldConfigList", {
    nodeGuidList: nodeGuids
  });
  return response.map(res => {
    res.valueRange = res.valueRange ? JSON.parse(res.valueRange) : [];
    return res;
  });
}

export async function getTestGroupFieldConfig(testGroupGuid) {
  let response = await get(
    `/fieldconfig/GroupPersonFieldConfigList/${testGroupGuid}`
  );
  return response.map(res => {
    res.valueRange = res.valueRange ? JSON.parse(res.valueRange) : [];
    return res;
  });
}

export function getAllGroupFieldConfigRules(testGroupGuid) {
  return get(`/fieldconfig/AllGroupFieldConfigRulesList/${testGroupGuid}`);
}

export function getNodeFieldConfigRules(nodeGuid) {
  return get(`/fieldconfig/AllFieldConfigRuleListFromNode/${nodeGuid}`);
}

export function updateFieldConfig(updatePropList, newConfig) {
  return post("/fieldconfig/UpdatePersonFieldConfig", {
    updatePropList,
    newConfig
  });
}
