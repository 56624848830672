var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AppLoadingSpinner',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("被试者属性名称")]),_c('v-card-text',[_vm._v("点击下方表格中的内容处，可修改配置")]),_c('v-data-table',{staticClass:"mx-8",attrs:{"headers":_vm.fieldConfigHeaders,"items":_vm.fieldConfigList,"item-key":"guid","sort-by":['order'],"sort-desc":[false],"disable-pagination":"","hide-default-footer":"","loading-text":"正在读取属性名称，请稍候...","no-data-text":"未找到任何属性","no-results-text":"未找到任何匹配的属性"},scopedSlots:_vm._u([{key:"item.fieldAlias",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.fieldAlias,"large":"","persistent":"","save-text":"保存","cancel-text":"取消"},on:{"update:returnValue":function($event){return _vm.$set(item, "fieldAlias", $event)},"update:return-value":function($event){return _vm.$set(item, "fieldAlias", $event)},"save":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'fieldAlias',
                  item.fieldAlias
                )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("更新显示名称")]),_c('v-text-field',{attrs:{"single-line":"","dense":"","autofocus":"","label":"显示名称"},model:{value:(item.fieldAlias),callback:function ($$v) {_vm.$set(item, "fieldAlias", $$v)},expression:"item.fieldAlias"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(item.fieldAlias))])])]}},{key:"item.valueRange",fn:function(ref){
                var item = ref.item;
return [(item.isAllowValueRange)?_c('v-edit-dialog',{attrs:{"large":"","persistent":"","save-text":"保存","cancel-text":"取消"},on:{"open":function($event){_vm.originalValueRange = [].concat( item.valueRange )},"cancel":function($event){item.valueRange = _vm.originalValueRange},"close":function($event){_vm.newValueRangeItem = ''},"save":function($event){_vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'valueRange',
                  JSON.stringify(item.valueRange)
                )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("更新取值范围")]),_c('div',_vm._l((item.valueRange),function(val,idx){return _c('v-chip',{key:idx,staticClass:"mr-1 my-1",attrs:{"small":"","close":""},on:{"click:close":function($event){return item.valueRange.splice(idx, 1)}}},[_vm._v(" "+_vm._s(val)+" ")])}),1),_c('v-text-field',{attrs:{"single-line":"","autofocus":"","label":"输入要添加的值"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.addToValueRange(item.valueRange)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" 添加 ")],1)]},proxy:true}],null,true),model:{value:(_vm.newValueRangeItem),callback:function ($$v) {_vm.newValueRangeItem=$$v},expression:"newValueRangeItem"}})]},proxy:true}],null,true)},[_c('div',[_vm._l((item.valueRange),function(val,idx){return _c('v-chip',{key:idx,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(val)+" ")])}),(!item.valueRange || !item.valueRange.length)?_c('span',{staticClass:"text-caption"},[_vm._v(" 点击添加取值范围 ")]):_vm._e()],2)]):_vm._e()]}},{key:"item.rulesGuid",fn:function(ref){
                var item = ref.item;
return [(!item.valueRange || !item.valueRange.length)?_c('v-edit-dialog',{attrs:{"large":"","persistent":"","save-text":"保存","cancel-text":"取消"},on:{"save":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'rulesGuid',
                  item.rulesGuid
                )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 mr-6 text-h6"},[_vm._v("更新属性的类型")]),_c('v-select',{attrs:{"single-line":"","label":"选择属性的类型","items":_vm.fieldConfigRuleSelectList,"item-text":"name","item-value":"guid"},model:{value:(item.rulesGuid),callback:function ($$v) {_vm.$set(item, "rulesGuid", $$v)},expression:"item.rulesGuid"}})]},proxy:true}],null,true)},[_c('div',[(item.rulesGuid)?_c('v-chip',{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getConfigRuleNameFromGuid(item.rulesGuid))+" ")]):_c('span',{staticClass:"text-caption"},[_vm._v(" 点击添加属性类型 ")])],1)]):_vm._e()]}},{key:"item.isVisible",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","disabled":item.fieldName === 'gender' ||
                  item.fieldName === 'age' ||
                  item.fieldName === 'nodeGuid'},on:{"change":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'isVisible',
                  item.isVisible
                )}},model:{value:(item.isVisible),callback:function ($$v) {_vm.$set(item, "isVisible", $$v)},expression:"item.isVisible"}})]}},{key:"item.isRequired",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","disabled":item.fieldName === 'gender' ||
                  item.fieldName === 'age' ||
                  item.fieldName === 'nodeGuid'},on:{"change":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'isRequired',
                  item.isRequired
                )}},model:{value:(item.isRequired),callback:function ($$v) {_vm.$set(item, "isRequired", $$v)},expression:"item.isRequired"}})]}},{key:"item.isVisibleInTable",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'isVisibleInTable',
                  item.isVisibleInTable
                )}},model:{value:(item.isVisibleInTable),callback:function ($$v) {_vm.$set(item, "isVisibleInTable", $$v)},expression:"item.isVisibleInTable"}})]}},{key:"item.isVisibleInReport",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'isVisibleInReport',
                  item.isVisibleInReport
                )}},model:{value:(item.isVisibleInReport),callback:function ($$v) {_vm.$set(item, "isVisibleInReport", $$v)},expression:"item.isVisibleInReport"}})]}},{key:"item.order",fn:function(ref){
                var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.order,"large":"","persistent":"","save-text":"保存","cancel-text":"取消"},on:{"update:returnValue":function($event){return _vm.$set(item, "order", $event)},"update:return-value":function($event){return _vm.$set(item, "order", $event)},"save":function($event){_vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'order',
                  Number(item.order)
                )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("更新字段排序")]),_c('v-text-field',{attrs:{"single-line":"","dense":"","autofocus":"","label":"字段排序"},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(item.order))])])]}}],null,true)})],1)],1)],1),_c('AppMessageBox',{attrs:{"title":"发生错误"},model:{value:(_vm.errorMsg),callback:function ($$v) {_vm.errorMsg=$$v},expression:"errorMsg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }