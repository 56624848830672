<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-4 my-4">
      <v-card-title>统一测量控制</v-card-title>
      <v-card-text v-html="controlBtnDesc"></v-card-text>
      <v-btn
        class="ml-6"
        x-large
        :color="controlBtnColor"
        @click="isTogglingCanStartTest = true"
      >
        <v-icon class="mr-2">{{ controlBtnIcon }}</v-icon>
        <span>{{ controlBtnName }}</span>
      </v-btn>
    </v-card>
    <v-divider class="my-4"></v-divider>
    <v-card flat class="mx-4 my-4 stat-chart-div">
      <v-card-title>
        <span>信息统计</span>
      </v-card-title>
      <v-card-text class="px-6 ">
        <div class="d-flex align-center justify-space-between mb-4">
          <v-btn-toggle
            v-model="currentChartMode"
            mandatory
            dense
            color="primary"
          >
            <v-btn>测量日期</v-btn>
            <v-btn>量表</v-btn>
          </v-btn-toggle>
          <div class="d-flex align-center">
            <AppDateRangePicker
              outlined
              label="测量日期"
              init-with-default
              v-model="pickedDateRange"
            />
            <AppTooltipBtn
              class="ml-2"
              color="primary"
              icon="mdi-refresh"
              tooltip="刷新图表数据"
              @click="reloadNodeTestData(nodeGuid)"
            />
          </div>
        </div>
        <TestCaseCountBar
          :title="nodeName"
          :chart-mode="currentChartMode"
          :case-stat-list="nodeTestCaseStatList"
        />
      </v-card-text>
    </v-card>
    <AppDialog
      v-model="isTogglingCanStartTest"
      size="small"
      :title="`确定要 ${controlBtnName} 吗？`"
      :color="controlBtnColor"
      :action-text="controlBtnName"
      @confirm="toggleCanStartTestDirectly"
    >
      <span>{{ controlBtnDialogDesc }}</span>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppDateRangePicker from "@/components/AppDateRangePicker";
import TestCaseCountBar from "@/components/statistics/TestCaseCountBar";
import { nodeTestCases } from "@/api/statistic";
import {
  fetchCanStartTestDirectly,
  updateCanStartTestDirectly
} from "@/api/manageNode";
import {
  getStartDateFromDateRange,
  getEndDateFromDateRange
} from "@/utils/dateTime";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    AppTooltipBtn,
    AppDateRangePicker,
    TestCaseCountBar
  },

  props: {
    nodeGuid: {
      type: String,
      required: true
    },
    nodeName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      canStartTestDirectly: false,
      nodeTestCaseStatList: [],
      pickedDateRange: [],
      currentChartMode: 0,
      // dialogs
      isTogglingCanStartTest: false,
      errorMsg: ""
    };
  },

  watch: {
    nodeGuid(newVal) {
      this.reloadNodeTestData(newVal);
      this.reloadCanStartTestDirectly(newVal);
    },
    pickedDateRange(newDateRange) {
      // 只有 dateRange 只选择了一个日期的情况下不更新
      if (!newDateRange || newDateRange.length != 1) {
        this.reloadNodeTestData(this.nodeGuid);
      }
    }
  },

  computed: {
    controlBtnName() {
      return this.canStartTestDirectly ? "关闭自由答题" : "开始答题";
    },
    controlBtnIcon() {
      return this.canStartTestDirectly
        ? "mdi-stop-circle-outline"
        : "mdi-play-box-multiple-outline";
    },
    controlBtnColor() {
      return this.canStartTestDirectly ? "warning" : "success";
    },
    controlBtnDesc() {
      return this.canStartTestDirectly
        ? "目前状态为：自由答题。 被试者进入答题界面后，可直接开始答题。"
        : "目前状态为：自由答题已关闭。 被试者进入答题界面后，无法开始测量；需等待管理员点击“开始答题”按钮才能开始答题。";
    },
    controlBtnDialogDesc() {
      return this.canStartTestDirectly
        ? "关闭自由答题后，被试者进入答题界面后，将无法开始测量，直到点击“开始答题”按钮才能开始答题。"
        : "开始答题后，正在等待答题的被试者，可直接开始答题；其他被试者，进入答题界面也可直接开始答题。";
    }
  },

  methods: {
    async reloadCanStartTestDirectly(nodeGuid) {
      try {
        this.isLoading = true;
        this.canStartTestDirectly = await fetchCanStartTestDirectly(nodeGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async reloadNodeTestData(nodeGuid) {
      try {
        this.isLoading = true;
        this.nodeTestCaseStatList = await nodeTestCases(
          nodeGuid,
          getStartDateFromDateRange(this.pickedDateRange),
          getEndDateFromDateRange(this.pickedDateRange)
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async toggleCanStartTestDirectly() {
      this.isTogglingCanStartTest = false;
      try {
        this.isLoading = true;
        await updateCanStartTestDirectly(
          this.nodeGuid,
          !this.canStartTestDirectly
        );
        // 提交成功后再修改页面上的值
        this.canStartTestDirectly = !this.canStartTestDirectly;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    }
  },

  created() {
    this.reloadCanStartTestDirectly(this.nodeGuid);
    this.reloadNodeTestData(this.nodeGuid);
  }
};
</script>

<style lang="scss" scoped>
.stat-chart-div {
  overflow: auto;
  max-width: 650px;
}
</style>
