export const caseStatusDict = {
  completed: {
    value: "completed",
    text: "已完成",
    color: "#5470c6"
  },
  in_progress: {
    value: "in_progress",
    text: "正在进行",
    color: "#91cc75"
  },
  saved: {
    value: "saved",
    text: "暂存退出",
    color: "#73c0de"
  },
  cancelled: {
    value: "cancelled",
    text: "放弃答题",
    color: "#bebebe"
  },
  wait_to_start: {
    value: "wait_to_start",
    text: "等待开始",
    color: "#fac858"
  }
};
