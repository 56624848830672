<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mt-4 ml-4">
      <v-card-title>
        识别号类型设置
        <AppTooltipBtn
          btn-class="ml-4"
          color="primary"
          icon="mdi-plus-circle"
          tooltip="添加识别号类型"
          @click="identityTypeCreationDialogOpen"
        />
      </v-card-title>
      <v-data-table
        class="mx-8"
        :headers="identityTypeTableHeaders"
        :items="nodeIdentityTypes"
        item-key="guid"
        disable-sort
        disable-pagination
        hide-default-footer
        :loading="isLoadingDatatable"
        loading-text="正在加载识别号类型，请稍候..."
        no-data-text="未找到任何识别号类型，点击“+”添加"
      >
        <template v-slot:[`header.isSecure`]="{ header }">
          {{ header.text }}
          <AppTooltipBtn
            color="grey"
            small-icon
            icon="mdi-help-circle-outline"
            :tooltip="header.tooltip"
          />
        </template>
        <template v-slot:[`item.isSecure`]="{ item }">
          <v-switch
            inset
            v-model="item.isSecure"
            @change="updateIsSecureToIdentityType(item.guid, item.isSecure)"
          ></v-switch>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <AppTooltipBtn
            btn-class="mr-2"
            color="error"
            icon="mdi-delete"
            tooltip="删除此识别号类型"
            @click="identityTypeDeleteDialogOpen(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <AppDialog
      v-model="isShowIdentityTypeCreationDialog"
      size="small"
      title="添加新的识别号类型"
      color="green"
      action-text="添加"
      :loading="isLoadingDialog"
      @confirm="addNewIdentityType"
      @closed="identityTypeCreationDialogClosed"
    >
      <v-select
        :items="pickableIdentityTypes"
        item-value="guid"
        item-text="text"
        v-model="selectedNewIdentityTypeGuid"
        :loading="isLoadingAllTypes"
      ></v-select>
    </AppDialog>
    <AppDialog
      v-model="isShowIdentityTypeDeleteDialog"
      color="error"
      size="small"
      :title="`是否确定要删除识别号类型 ${actionIdentityTypeText} 吗？`"
      action-text="确认删除"
      @confirm="deleteIdentityTypeConfirmed"
      @closed="identityTypeDeleteDialogClosed"
    >
      不会影响现有的数据，如需要可重新添加。
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  fetchAllIdentityTypeList,
  fetchVisibleIdentityTypeList,
  addNewIdentityType,
  updateIsSecureToIdentityType,
  deleteOneIdentityType
} from "@/api/userIdentity";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    AppTooltipBtn
  },

  props: {
    nodeGuid: {
      type: String,
      required: true
    },
    nodeType: {
      type: String
    }
  },

  data() {
    return {
      isLoading: false,
      isLoadingDatatable: false,
      isLoadingAllTypes: false,
      isLoadingDialog: false,
      errorMsg: "",
      identityTypeTableHeaders: [],
      pickableIdentityTypes: [],
      nodeIdentityTypes: [],
      selectedNewIdentityTypeGuid: "",
      actionIdentityTypeGuid: "",
      actionIdentityTypeText: "",
      isShowIdentityTypeCreationDialog: false,
      isShowIdentityTypeDeleteDialog: false
    };
  },

  watch: {
    nodeGuid() {
      this.getNodeIdentityTypes();
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid"
    })
  },

  methods: {
    initTableHeaders() {
      this.identityTypeTableHeaders = [
        {
          text: "类型名",
          value: "text"
        },
        {
          text: "是否加密",
          value: "isSecure",
          tooltip: "是否在显示中添加星号。开启后在列表和报告显示中将打上星号。"
        },
        {
          text: "操作",
          value: "actions"
        }
      ];
    },
    async getPickableIdentityTypes() {
      try {
        this.isLoadingAllTypes = true;
        let allIdentityTypes = await fetchAllIdentityTypeList(this.regionGuid);
        this.pickableIdentityTypes = _.differenceBy(
          allIdentityTypes,
          this.nodeIdentityTypes,
          "text"
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoadingAllTypes = false;
    },
    async getNodeIdentityTypes() {
      try {
        this.isLoadingDatatable = true;
        let nodeIdTypes = await fetchVisibleIdentityTypeList(
          this.regionGuid,
          this.nodeType,
          [this.nodeGuid]
        );
        this.nodeIdentityTypes = nodeIdTypes || [];
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoadingDatatable = false;
    },
    async updateIsSecureToIdentityType(guid, isSecure) {
      try {
        this.isLoading = true;
        await updateIsSecureToIdentityType(guid, isSecure);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ===================== Add new identityType =====================
    identityTypeCreationDialogOpen() {
      this.isShowIdentityTypeCreationDialog = true;
      this.getPickableIdentityTypes();
    },
    identityTypeCreationDialogClosed() {
      this.selectedNewIdentityTypeGuid = "";
    },
    async addNewIdentityType() {
      try {
        this.isLoadingDialog = true;
        let selectedIdTypes = this.pickableIdentityTypes.filter(
          idt => idt.guid === this.selectedNewIdentityTypeGuid
        );
        if (selectedIdTypes && selectedIdTypes.length) {
          var idType = selectedIdTypes[0];
          await addNewIdentityType(this.regionGuid, {
            identityTypeGuid: idType.guid,
            nodeGuid: this.nodeGuid,
            typeValue: idType.value,
            typeText: idType.text
          });
        }
        this.isShowIdentityTypeCreationDialog = false;
        this.getNodeIdentityTypes();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoadingDialog = false;
    },
    // ===================== Delete identityType =====================
    identityTypeDeleteDialogOpen(clickedItem) {
      this.actionIdentityTypeGuid = clickedItem.guid;
      this.actionIdentityTypeText = clickedItem.text;
      this.isShowIdentityTypeDeleteDialog = true;
    },
    identityTypeDeleteDialogClosed() {
      this.actionIdentityTypeGuid = "";
      this.actionIdentityTypeText = "";
    },
    async deleteIdentityTypeConfirmed() {
      try {
        this.isLoadingDialog = true;
        await deleteOneIdentityType(this.actionIdentityTypeGuid);
        this.isShowIdentityTypeDeleteDialog = false;
        this.getNodeIdentityTypes();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoadingDialog = false;
    }
  },

  created() {
    this.initTableHeaders();
    this.getNodeIdentityTypes();
  }
};
</script>
