import { get, post } from "@/utils/axios";

export async function fetchReportSignInfo(nodeGuid) {
  var reportSign = await get("/reportinfo/InitOrGetReportSignInfo", {
    nodeGuid
  });
  return {
    reportInfoGuid: reportSign.reportInfoGuid,
    reportOrgName: reportSign.reportOrgName,
    reporterCandidates: reportSign.reporterCandidatesJson
      ? JSON.parse(reportSign.reporterCandidatesJson)
      : []
  };
}

export function saveReportSignInfo(
  reportInfoGuid,
  { reportOrgName, reporterCandidates }
) {
  return post("/reportinfo/UpdateReportSignInfo", {
    reportInfoGuid,
    reportOrgName,
    reporterCandidatesJson: JSON.stringify(reporterCandidates)
  });
}

export async function fetchCoverPageBody(nodeGuid) {
  let response = await get("/reportinfo/GetCoverPageBody", {
    nodeGuid
  });
  return response.value;
}

export function saveCoverPageBody(reportInfoGuid, coverPageHtml) {
  return post("/reportinfo/UpdateCoverPageContent", {
    reportInfoGuid,
    coverPageHtml
  });
}
