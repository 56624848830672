import { post } from "@/utils/axios";

export async function nodeTestCases(nodeGuid, startDate, endDate) {
  let response = await post("/statistic/ManageNodeTestStatistic", {
    manageNodeGuid: nodeGuid,
    startDate,
    endDate
  });
  return response;
}
