<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-4 my-4" max-width="700px">
      <v-card-title>基本设置</v-card-title>
      <v-alert
        type="success"
        dense
        transition="slide-y-transition"
        :value="isUpdateSuccess"
      >
        更新成功！
      </v-alert>
      <div class="px-6">
        <v-text-field
          filled
          dense
          label="测评单位名称"
          hint="测评单位的显示名称"
          v-model="nodeName"
          :rules="fieldRules.nodeName"
        ></v-text-field>
      </div>
      <v-card-title>测量端设置</v-card-title>
      <div class="px-6">
        <v-select
          filled
          dense
          label="默认的被试者信息输入界面"
          :items="primaryPersonInputTypes"
          item-value="value"
          item-text="text"
          v-model="primaryPersonInput"
        ></v-select>
      </div>
      <v-card-actions class="mt-4 mb-6">
        <v-btn
          color="primary"
          depressed
          :disabled="isBtnLoading"
          :loading="isBtnLoading"
          @click="saveChanges"
        >
          确认修改
        </v-btn>
      </v-card-actions>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { mapGetters } from "vuex";
import _ from "lodash";
import { fetchManageNodeDetails, updateNodeDetails } from "@/api/manageNode";
import { personInputTypes } from "@/utils/constants/user";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    nodeGuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      isBtnLoading: false,
      errorMsg: "",
      isUpdateSuccess: false,
      // node details
      nodeName: "",
      primaryPersonInput: "",
      fieldRules: {
        nodeName: [val => (val || "").length > 0 || "名称不能为空"],
        timeMinutes: [
          val =>
            (!isNaN(Number(val)) && Number(val) >= 0) || "必须输入大于零的数字",
          val => Number(val) < 9999 || "答题时间不能大于9999分钟"
        ]
      }
    };
  },

  watch: {
    nodeGuid(newVal) {
      this.fetchNodeDetails(newVal);
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid"
    }),
    primaryPersonInputTypes() {
      return _.map(personInputTypes, val => val);
    }
  },

  methods: {
    showSuccessAlert() {
      this.isUpdateSuccess = true;
      setTimeout(() => (this.isUpdateSuccess = false), 2000);
    },
    async fetchNodeDetails(guid) {
      try {
        this.isLoading = true;
        let nodeDetails = await fetchManageNodeDetails(guid);
        this.nodeName = nodeDetails.name;
        this.primaryPersonInput = nodeDetails.primaryPersonInput;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async saveChanges() {
      try {
        this.isBtnLoading = true;
        // 保存修改
        await updateNodeDetails(this.nodeGuid, {
          name: this.nodeName,
          primaryPersonInput: this.primaryPersonInput
        });
        // 重新获取 manageNode details
        await this.fetchNodeDetails(this.nodeGuid);
        this.showSuccessAlert();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    }
  },

  created() {
    this.fetchNodeDetails(this.nodeGuid);
  }
};
</script>
