<template>
  <div class="d-inline-block">
    <v-tooltip bottom v-if="!!tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          :text="text"
          :small="small"
          :rounded="rounded"
          :disabled="disabled"
          :icon="isIconBtn"
          :class="btnClass"
          :color="color"
          v-on="on"
          v-bind="attrs"
          @click="clicked"
        >
          <v-icon
            v-if="!!icon"
            :class="{ 'mr-2': !isIconBtn }"
            :small="smallIcon"
          >
            {{ icon }}
          </v-icon>
          <span>{{ label }}</span>
        </v-btn>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-btn
      v-else
      depressed
      :text="text"
      :small="small"
      :rounded="rounded"
      :disabled="disabled"
      :icon="isIconBtn"
      :class="btnClass"
      :color="color"
      @click="clicked"
    >
      <v-icon v-if="!!icon" :class="{ 'mr-2': !isIconBtn }" :small="smallIcon">
        {{ icon }}
      </v-icon>
      <span>{{ label }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    rounded: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean
    },
    small: {
      type: Boolean,
      default: false
    },
    smallIcon: {
      type: Boolean,
      default: false
    },
    btnClass: {
      type: String
    },
    color: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String
    },
    tooltip: {
      type: String
    }
  },

  computed: {
    isIconBtn() {
      return this.icon && !this.label;
    }
  },

  methods: {
    clicked() {
      this.$emit("click");
    }
  }
};
</script>
