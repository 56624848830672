<template>
  <v-menu
    v-model="isShowMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="mr-4 date-range-picker"
        :style="fieldStyle"
        :value="dateRangeText"
        prepend-inner-icon="mdi-calendar-text"
        :label="label"
        :filled="filled"
        :outlined="outlined"
        :single-line="singleLine"
        dense
        readonly
        hide-details="auto"
        :clearable="clearable"
        v-on="on"
        v-bind="attrs"
        @click:clear="clearDateRange"
      ></v-text-field>
    </template>
    <v-card>
      <v-card-title>
        {{ label }}
        <v-spacer></v-spacer>
        <v-select
          class="px-4 preset-select"
          outlined
          dense
          hide-details="auto"
          label="快速选择"
          v-model="selectedPresetRange"
          :items="presetRangeItems"
          @change="setStartEndDateFromPresetItem"
        ></v-select>
      </v-card-title>
      <v-date-picker
        v-model="localDateRange"
        full-width
        no-title
        range
        show-adjacent-months
        locale="zh-cn"
        :day-format="d => d.split('-')[2]"
        @change="datePickerSelectionChanged"
      ></v-date-picker>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="clickCloseBtn">关闭</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import _ from "lodash";
import {
  presetDateRangeSelects,
  presetFutureDateRangeSelects,
  getStartEndDateFromPresetRange,
  getFutureStartEndDateFromPresetRange,
  getDateFromDateTime
} from "@/utils/dateTime";

export default {
  props: {
    fieldStyle: {
      type: String
    },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      default: "350px"
    },
    dateRange: {
      type: Array,
      required: true
    },
    isFutureDate: {
      type: Boolean,
      default: false
    },
    initWithDefault: {
      type: Boolean,
      default: false
    }
  },

  model: {
    prop: "dateRange",
    event: "changed"
  },

  data() {
    return {
      isShowMenu: false,
      selectedPresetRange: "",
      presetRangeItems: [],
      localDateRange: this.dateRange
    };
  },

  watch: {
    isShowMenu(newVal) {
      if (newVal) {
        this.initPresetRangeItems();
        this.initDefaultPresetRange();
      }
    },
    dateRange(newVal) {
      this.localDateRange = newVal;
    },
    localDateRange(newVal) {
      // 不添加此条件，会造成死循环
      newVal !== this.dateRange &&
        this.$emit("changed", this.rebuildDateRange(newVal));
    }
  },

  computed: {
    dateRangeText() {
      let dText = "";
      if (this.localDateRange.length === 1) {
        dText = getDateFromDateTime(this.localDateRange[0]);
      } else if (this.localDateRange.length === 2) {
        dText = `${getDateFromDateTime(
          this.localDateRange[0]
        )} ~ ${getDateFromDateTime(this.localDateRange[1])}`;
      }
      return dText;
    }
  },

  methods: {
    initPresetRangeItems() {
      if (this.isFutureDate) {
        this.presetRangeItems = presetFutureDateRangeSelects;
      } else {
        this.presetRangeItems = presetDateRangeSelects;
      }
    },
    initDefaultPresetRange() {
      if (this.initWithDefault) {
        this.selectedPresetRange = this.presetRangeItems.find(
          item => item.default
        );
        this.setStartEndDateFromPresetItem();
      }
    },
    setStartEndDateFromPresetItem() {
      let startEndDate = {};
      if (this.isFutureDate) {
        startEndDate = getFutureStartEndDateFromPresetRange(
          this.selectedPresetRange
        );
      } else {
        startEndDate = getStartEndDateFromPresetRange(this.selectedPresetRange);
      }
      // 全为空表示“全部的时间”
      if (startEndDate.startDate && startEndDate.endDate) {
        this.localDateRange = [startEndDate.startDate, startEndDate.endDate];
      } else {
        this.localDateRange = [];
      }
    },
    datePickerSelectionChanged() {
      // 手动选择日期后，自动取消快速选择
      this.selectedPresetRange = "";
    },
    rebuildDateRange(dateRangeArray) {
      let newDateRange = [];
      if (dateRangeArray.length === 1) {
        newDateRange = dateRangeArray;
      } else if (dateRangeArray.length === 2) {
        newDateRange = [_.min(dateRangeArray), _.max(dateRangeArray)];
      }
      return newDateRange;
    },
    clearDateRange() {
      this.localDateRange = [];
      // 同时删除 preset 的选中值
      this.selectedPresetRange = "";
    },
    clickCloseBtn() {
      this.isShowMenu = false;
    }
  },

  created() {
    this.initPresetRangeItems();
    this.initDefaultPresetRange();
  }
};
</script>

<style lang="scss" scoped>
.date-range-picker {
  max-width: 400px;
  min-width: 290px;
}
.preset-select {
  max-width: 200px;
}
</style>
