import { init } from "echarts";
import { caseStatusDict } from "@/utils/constants/case";

let testCaseStatChart = null;

export function drawTestCaseStatChart(
  chartDOM,
  {
    chartTitle,
    chartSubtext,
    dateSeries,
    visualMarkData,
    tooltipPosition,
    tooltipCallback
  }
) {
  testCaseStatChart = init(chartDOM);
  let chartOptions = {
    title: {
      text: chartTitle,
      subtext: chartSubtext
    },
    grid: {
      top: 80,
      left: 100
    },
    toolbox: {
      show: true,
      right: 40,
      feature: {
        saveAsImage: {
          title: "保存为图片"
        }
      }
    },
    tooltip: {
      trigger: "axis",
      triggerOn: "mousemove",
      alwaysShowContent: false,
      axisPointer: {
        type: "shadow"
      },
      position: tooltipPosition,
      formatter: tooltipCallback
    },
    xAxis: {
      type: "value"
    },
    yAxis: {
      type: "category",
      axisLabel: {
        width: 90,
        overflow: "break"
      },
      data: dateSeries
    },
    legend: {
      top: 40,
      right: 40,
      data: [
        caseStatusDict.completed.text,
        caseStatusDict.wait_to_start.text,
        caseStatusDict.in_progress.text,
        caseStatusDict.saved.text,
        caseStatusDict.cancelled.text
      ]
    },
    series: [
      {
        name: "已完成",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.completed,
        itemStyle: {
          color: caseStatusDict.completed.color
        }
      },
      {
        name: "等待开始",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.waitToStart,
        itemStyle: {
          color: caseStatusDict.wait_to_start.color
        }
      },
      {
        name: "正在进行",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.inProgress,
        itemStyle: {
          color: caseStatusDict.in_progress.color
        }
      },
      {
        name: "暂存退出",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.saved,
        itemStyle: {
          color: caseStatusDict.saved.color
        }
      },
      {
        name: "放弃答题",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.cancelled,
        itemStyle: {
          color: caseStatusDict.cancelled.color
        }
      }
    ]
  };
  testCaseStatChart.setOption(chartOptions);
}

export function disposeChart() {
  // 清空原有图像
  if (testCaseStatChart) {
    testCaseStatChart.dispose();
    testCaseStatChart = null;
  }
}
