<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-alert
      type="success"
      dense
      transition="slide-y-transition"
      :value="isUpdateSuccess"
      >更新成功！</v-alert
    >
    <v-row>
      <v-col cols="11">
        <v-form ref="form">
          <v-card flat max-width="600px">
            <v-card-title>主试机构设置</v-card-title>
            <v-card-text>
              <v-text-field
                filled
                dense
                label="主试机构名称"
                hint="案例报告底部左侧的主试机构名称"
                v-model="reportOrgName"
                :rules="fieldRules.name"
              ></v-text-field>
            </v-card-text>
            <v-card-title>报告署名候选</v-card-title>
            <v-card-text>
              <div>
                <v-chip
                  v-for="(candiName, idx) in reporterCandidates"
                  :key="idx"
                  class="mr-2 mb-4"
                  close
                  @click:close="reporterCandidates.splice(idx, 1)"
                >
                  {{ candiName }}
                </v-chip>
              </div>
              <v-text-field
                dense
                label="报告署名候选"
                hint="报告底部的署名选择"
                v-model="newCandidateName"
              >
                <template v-slot:append-outer>
                  <v-btn
                    text
                    small
                    color="primary"
                    @click="addSignCandidateName"
                  >
                    <v-icon>mdi-plus</v-icon>
                    添加
                  </v-btn>
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-actions class="ml-4 mb-6">
              <v-btn
                color="primary"
                depressed
                @click="saveReportInfo"
                :disabled="isBtnLoading"
                :loading="isBtnLoading"
                >确认修改</v-btn
              >
            </v-card-actions>
            <v-divider></v-divider>
          </v-card>
          <v-card v-if="coverPageHtml" flat>
            <v-card-title>报告封面预览</v-card-title>
            <div class="mx-6 mb-6 page-container">
              <div class="page-a4" v-html="coverPageHtml"></div>
            </div>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { mapGetters } from "vuex";
import {
  fetchReportSignInfo,
  saveReportSignInfo,
  fetchCoverPageBody
} from "@/api/reportInfo";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    nodeGuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      reportInfoGuid: "",
      reportOrgName: "",
      reporterCandidates: [],
      newCandidateName: "",
      coverPageHtml: "",
      fieldRules: {
        name: [
          val => (val || "").length > 0 || "机构显示名称不能为空",
          val => (val || "").length < 25 || "机构显示名称不能超过30个字符"
        ]
      },
      isUpdateSuccess: false,
      errorMsg: "",
      isBtnLoading: false
    };
  },

  watch: {
    nodeGuid(newVal) {
      this.loadReportSignInfo(newVal);
      this.fetchCoverPageHtml(newVal);
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid"
    })
  },

  methods: {
    showSuccessMsg() {
      this.isUpdateSuccess = true;
      setTimeout(() => (this.isUpdateSuccess = false), 2000);
    },
    addSignCandidateName() {
      if (this.newCandidateName) {
        this.reporterCandidates.push(this.newCandidateName);
        this.newCandidateName = "";
      }
    },
    async loadReportSignInfo(nodeGuid) {
      try {
        this.isLoading = true;
        var reportSign = await fetchReportSignInfo(nodeGuid);
        this.reportInfoGuid = reportSign.reportInfoGuid;
        this.reportOrgName = reportSign.reportOrgName;
        this.reporterCandidates = reportSign.reporterCandidates;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async fetchCoverPageHtml(nodeGuid) {
      try {
        this.isLoading = true;
        this.coverPageHtml = await fetchCoverPageBody(nodeGuid);
      } catch (err) {
        this.coverPageHtml = "";
      }
      this.isLoading = false;
    },
    async saveReportInfo() {
      var isValid = this.$refs.form.validate();
      if (isValid) {
        try {
          this.isBtnLoading = true;
          await saveReportSignInfo(this.reportInfoGuid, {
            reportOrgName: this.reportOrgName,
            reporterCandidates: this.reporterCandidates
          });
          this.showSuccessMsg();
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isBtnLoading = false;
      }
    }
  },

  created() {
    this.loadReportSignInfo(this.nodeGuid);
    this.fetchCoverPageHtml(this.nodeGuid);
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  overflow-x: auto;
  .page-a4 {
    width: 210mm;
    height: 297mm;
    border: 1px solid #666;
  }
}
</style>
