import { get, post } from "@/utils/axios.js";

export function fetchAllIdentityTypeList(regionGuid) {
  return get(`/userIdentity/AllIdentityTypeList/${regionGuid}`);
}

export function fetchVisibleIdentityTypeList(
  regionGuid,
  nodeType,
  nodeGuidList
) {
  return post("/userIdentity/VisibleIdentityTypeList", {
    customerGuid: regionGuid,
    nodeType: nodeType || "",
    nodeGuidList
  });
}

export async function addNewIdentityType(
  regionGuid,
  { identityTypeGuid, nodeGuid, typeValue, typeText }
) {
  let response = await post(`/userIdentity/AddNewIdentityType/${regionGuid}`, {
    guid: identityTypeGuid,
    nodeGuid,
    value: typeValue,
    text: typeText
  });
  return response.guid;
}

export async function updateIsSecureToIdentityType(identityTypeGuid, isSecure) {
  let response = await post(
    `/userIdentity/UpdateIsSecureForIdentityType/${identityTypeGuid}`,
    { value: isSecure }
  );
  return response.guid;
}

export async function deleteOneIdentityType(identityTypeGuid) {
  let response = await post("/userIdentity/DeleteOneIdentityType", {
    guid: identityTypeGuid
  });
  return response.guid;
}
