import { get, post } from "@/utils/axios";

export function fetchManageNodeTreeList(regionGuid, nodeGuids) {
  return post("/managenode/ManageNodeTreeList", {
    customerGuid: regionGuid,
    nodeGuidList: nodeGuids
  });
}

export async function fetchManageNodeFlatSelectList(regionGuid, nodeGuids) {
  let response = await post("/managenode/ManageNodeFlatSelectList", {
    customerGuid: regionGuid,
    nodeGuidList: nodeGuids
  });
  return response.itemList;
}

export async function fetchClientNodesFromSuper(superNodeGuid) {
  let response = await post("/managenode/ClientManageNodesFromSuper", {
    guid: superNodeGuid
  });
  return response.itemList;
}

export function fetchManageNodeDetails(nodeGuid) {
  return get(`/managenode/ManageNodeDetails/${nodeGuid}`);
}

export async function updateNodeDetails(
  nodeGuid,
  {
    name,
    description,
    primaryPersonInput,
    testPwd,
    canStartTest,
    isShowScaReport,
    isContinuousTest
  }
) {
  let response = await post("/managenode/SaveManageNodeDetails", {
    guid: nodeGuid,
    name,
    description,
    primaryPersonInput,
    testPwd,
    canStartTest,
    isShowScaReport,
    isContinuousTest
  });
  return response.guid;
}

export async function fetchPrimaryPersonInput(nodeGuid) {
  let response = await get(
    `/managenode/PrimaryPersonInputFromManageNode/${nodeGuid}`
  );
  return response.value;
}

export async function fetchCanStartTestDirectly(nodeGuid) {
  let response = await get(
    `/managenode/CanStartTestDirectlyFromNode/${nodeGuid}`
  );
  return response.value;
}

export async function updateCanStartTestDirectly(nodeGuid, canStartTest) {
  let response = await post(
    `/managenode/UpdateCanStartTestForNode/${nodeGuid}`,
    {
      value: canStartTest
    }
  );
  return response.guid;
}

export async function updateIsNodeEnabled(nodeGuid, isEnabled) {
  let response = await post(`/managenode/UpdateIsEnabledForNode/${nodeGuid}`, {
    value: isEnabled
  });
  return response.guid;
}

export async function createNewNode(regionGuid, parentNodeGuid, nodeName) {
  let response = await post("/managenode/CreateOneManageNodeFromParent", {
    customerGuid: regionGuid,
    parentNodeGuid,
    nodeName
  });
  return response.guid;
}

export async function deleteOneNode(regionGuid, nodeGuid, isDeleteAllChildren) {
  let response = await post("/managenode/DeleteOneManageNode", {
    customerGuid: regionGuid,
    manageNodeGuid: nodeGuid,
    deleteAllChildren: isDeleteAllChildren
  });
  return response.guid;
}

export function fetchNodePickableLbList(nodeGuid) {
  return get("/managenodelb/PickableLbListFromManageNode", {
    nodeGuid
  });
}

export function fetchNodePickedLbList(nodeGuid) {
  return get("/managenodelb/PickedLbListFromManageNode", { nodeGuid });
}

export async function addLbsToNode(nodeGuid, lbGuidList) {
  let response = await post(
    `/managenodelb/AddLbsToManageNode/${nodeGuid}`,
    lbGuidList
  );
  return response.guid;
}

export async function removeLbsFromNode(removeLbGuidList) {
  let response = await post(
    "/managenodelb/RemoveLbsFromManageNode",
    removeLbGuidList
  );
  return response.guid;
}
